<template>
  <v-row>
    <v-col 
      v-if="label"
      cols="4"
      class="d-flex py-0"
    >
      <form-fields-label :label="label" />
    </v-col>
    <v-col 
      cols="5"
      class="py-0 pr-16 d-flex align-center"
    >
      <form-fields-text-and-switch 
        :switch-value="switchValue"
        :placeholder="placeholder"
        :suffix="suffix"
        :text-field-hint="hint"
        :switch-label="switchLabel" 
        :false-value="falseValue"
        :true-value="trueValue"
        :switch-prepend-label="switchPrependLabel"
        number-integer
        v-bind="$attrs"
        v-on="$listeners"
      />
    </v-col>
    <v-col 
      cols="3"
      class="py-0 pl-10 pr-0 d-flex align-center"
    >
      <slot />
    </v-col>
  </v-row>
</template>

<script>
import FormFieldsTextAndSwitch from '@/components/FormFieldsTextAndSwitch.vue'
import FormFieldsLabel from '@/components/FormFieldsLabel.vue'

export default {
  name: "BaseMultiField",
  components: {
    FormFieldsTextAndSwitch,
    FormFieldsLabel 
  },
  props: {
    hint: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: ""
    },
    numberInteger: {
      type: Boolean,
      default: false,
    },
    numberDecimal: {
      type: Boolean,
      default: false,
    },
    switchLabel: {
      type: String,
      default: "TTC"
    },
    falseValue: {
      type: [Boolean, String],
      default: true
    },
    trueValue: {
      type: [Boolean, String],
      default: false,
    },
    switchPrependLabel: {
      type: String,
      default: "HT"
    },
    switchValue: {
      type: [Boolean, String],
      default: false,
    },
  }
}
</script>