<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="currentTab"
            background-color="cassiopee-grey lighten-1"
            right
            show-arrows
            color="cassiopee-purple"
            class="site-definition-tabs mb-4"
          >
            <v-tab
              v-for="(localIt, index) in localIts"
              :key="index"
            >
              {{ localIt.nom || `Ressource IT 0${index}` }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-alert 
            message="Attention. Vérifiez bien les informations pour tous les sites enregistrés. Un onglet par site est créé " 
            icon="$warning"
          />
        </v-col>
      </v-row>
      <base-commentaire>
        Les ressources IT sont tous les locaux techniques, salles informatiques, datacenters, en hébergement en propre (interne) ou externalisé sous ses différentes formes: housing/colocation; IAAS/PAAS; Service SAAS. Il est important pour l'outil de bien répertorier tous les hébergements physiques également les hébergements type housing/colocation. Pour toutes les autres formes d’hébergement, celles-ci peuvent être complétées dans l’outil mais ne sont pas utilisées dans les résultats. 
      </base-commentaire>
      <v-row>
        <v-col cols="12">
          <form-fields-text
            v-model="niveau_completion_ressources_hebergement"
            field-label="Les ressources d'hébergement listées sont-elles complètes?"
            placeholder="% de complétude"
            field-description="Question générale valable pour tous les locaux IT"
            input-suffix="%"
            class="mt-2 mb-5"
            @blur="saveFormAnswer"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col 
              cols="5"
              xl="7"
            >
              <form-step-title title="Création de vos ressources d'hébergement" />
            </v-col>
            <v-col 
              cols="7" 
              xl="5"
              class="d-flex justify-space-between pl-7" 
            >
              <base-button
                width="250px"
                icon="$trashCan"
                @click="deleteCurrentLocalIt" 
              >
                Supprimer la ressource
              </base-button>
              <form-button-add-element 
                little-size
                outlined
                label="Ajouter une ressource"
                width="250px"
                @click="addLocalIt"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-text
            v-model="currentLocalIt.nom"
            field-label="Nom de la ressource d'hébergement"
            placeholder="Nom de la ressource d'hébergement"
            field-description="Tous sites géographiques d'hébergement. Exemples : datacenter, salle informatique, local IT, labos, cloud..."
            class="mt-5 mb-1"
            @blur="updateCurrentLocalIt"
          />
          <form-fields-multi-select 
            v-model="currentLocalIt.type_local_it"
            field-label="Type" 
            label="Types de ressource IT"
            :items="typeLocalIts"
            @change="updateCurrentLocalIt"
          />
          <v-row v-if="currentLocalIt.type_local_it === 'locaux IT non répertoriés'">
            <v-col cols="12">
              <form-fields-text
                v-model="currentLocalIt.ville"
                field-label="Localisation"
                field-description="Adresse physique."
                placeholder="Ville"
                @blur="updateCurrentLocalIt"
              />
            </v-col>
          </v-row>
          <v-row 
            v-if="currentLocalIt.type_local_it === 'locaux IT non répertoriés'"
            class="d-flex justify-end"  
          >
            <v-col cols="8 offset 4">
              <form-fields-text
                v-model="currentLocalIt.code_postal"
                field-description="Adresse physique."
                placeholder="Code Postal"
                type="number"
                number-integer
                @blur="updateCurrentLocalIt"
              />
              <form-fields-text
                v-model="currentLocalIt.adresse"
                field-description="Adresse physique."
                placeholder="Adresse"
                @blur="updateCurrentLocalIt"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels 
            accordion
            focusable
            flat
          >
            <v-expansion-panel 
              v-if="currentLocalIt.type_local_it === 'Autre ressource IT'"
              class="form-expansion-panel"
            >
              <v-expansion-panel-header color="cassiopee-purple--text text-h1 pl-3">
                Ressources IT non répertoriés
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <form-fields-text
                  v-model="currentLocalIt.autre_ressource_pourcentage"
                  field-label="Pourcentage du total des ressources IT"
                  placeholder="% du total des ressources IT"
                  field-description="Vous pouvez indiquer ici le pourcentage du SI externalisé (IAAS, PAAS, SAAS)"
                  input-suffix="%"
                  class="mt-6"
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-slider
                  v-model="currentLocalIt.niveau_performance_energetique"
                  field-label="Niveau de performance énergétique estimé"
                  placeholder="Niveau de performance énergétique estimé"
                  field-description="Valeur de 1 à 5."
                  type="number"
                  min="1"
                  max="5"
                  class="mt-10"
                  @change="updateCurrentLocalIt"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel 
              v-if="currentLocalIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre' || currentLocalIt.type_local_it === 'Housing / Colocation'"
              class="form-expansion-panel"  
            >
              <v-expansion-panel-header color="cassiopee-purple--text text-h1 pl-3">
                Définition du local informatique
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <form-fields-multi-select 
                  v-if="currentLocalIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre'"
                  v-model="currentLocalIt.site_id"
                  :field-label="`${currentLocalIt.nom} installé(e) sur un site ?`"
                  field-description="Si le site n'est pas listé ici, veuillez rajouter le site dans l'Etape 01 > B - Entité"
                  label="Liste des sites"
                  :items="sites"
                  item-value="id"
                  item-text="nom"
                  class="mt-6"
                  @change="updateCurrentLocalIt"
                />
                <form-fields-multi-select 
                  v-model="currentLocalIt.entite_id"
                  field-label="Responsable des ressources IT" 
                  label="Liste des entités"
                  :items="entites"
                  item-text="nom"
                  item-value="id"
                  class="mt-6"
                  @change="updateCurrentLocalIt"
                />
                <form-fields-multi-select 
                  v-model="currentLocalIt.entite_hebergee_ids"
                  :field-label="`Entités hébergées par ${currentLocalIt.nom}`" 
                  label="Liste des entités"
                  multiple
                  :items="esrEntites"
                  item-text="nom"
                  item-value="id"
                  @change="updateCurrentLocalIt"
                />
                <v-row
                  v-for="(entiteAutre, index) in entitesCreatedByFront"
                  :key="index"
                >
                  <div class="d-flex justify-space-between">
                    <form-fields-text
                      v-model="entiteAutre.nom"
                      field-label="Pouvez-vous préciser laquelle ?"
                      placeholder="Nom de l'entité hébergée"
                      @blur="updateEntiteCreatedByFront(entiteAutre.id)"
                    />
                    <base-button
                      width="200px"
                      icon="$trashCan"
                      @click="deleteEntite(entiteAutre.id)"
                    >
                      Supprimer l'entité
                    </base-button>
                  </div>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <form-button-add-element
                      class="d-flex justify-end mb-5"
                      label="Ajouter une entité"
                      outlined
                      @click="createEntiteCreatedByFront()"
                    />
                  </v-col>
                </v-row>
                <form-fields-radio-group
                  v-if="currentLocalIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre'" 
                  v-model="typeExploitantBatiment"
                  :field-label="`Qui exploite ${currentLocalIt.nom} partie bâtiment ?`" 
                  :radio-data-list="staffExploitationList"
                  @change="processExploitantBatiment"
                />
                <form-fields-text
                  v-if="typeExploitantBatiment === 'Autre'"
                  v-model="currentLocalIt.exploitant_batiment"
                  :field-label="`Pouvez-vous préciser qui exploite la/le ${currentLocalIt.nom} partie bâtiment ?`"
                  :placeholder="`Qui exploite ${currentLocalIt.nom} partie bâtiment ?`"
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-radio-group 
                  v-if="currentLocalIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre'" 
                  v-model="typeExploitantElectricite"
                  :field-label="`Qui exploite ${currentLocalIt.nom} partie électricité critique (onduleurs) et climatisation en salle ?`" 
                  :radio-data-list="staffExploitationList"
                  @change="processExploitantElectricite"
                />
                <form-fields-text
                  v-if="typeExploitantElectricite === 'Autre'"
                  v-model="currentLocalIt.exploitant_electricite"
                  :field-label="`Pouvez-vous préciser qui exploite ${currentLocalIt.nom} partie électricité critique (onduleurs) et climatisation en salle ?`"
                  :placeholder="`Qui exploite ${currentLocalIt.nom} partie électricité critique et climatisation en salle ?`"
                  @blur="updateCurrentLocalIt"
                />
                <!-- <form-fields-multi-select 
                  v-if="currentLocalIt.type_local_it !== 'Housing / Colocation'"
                  v-model="currentLocalIt.type_proprietaire_batiment"
                  field-label="Propriétaire ou locataire du bâtiment" 
                  label="Propriétaire/locataire/mixte"
                  :items="localItEnums.type_proprietaire_list"
                  @change="updateCurrentLocalIt"
                />
                <form-fields-multi-select 
                  v-if="currentLocalIt.type_local_it !== 'Housing / Colocation'"
                  v-model="currentLocalIt.type_proprietaire_infra"
                  field-label="Propriétaire ou locataire des infrastructures IT" 
                  label="Propriétaire/locataire/mixte"
                  :items="localItEnums.type_proprietaire_list"
                  @change="updateCurrentLocalIt"
                /> -->
                
                <form-fields-multi-select 
                  v-if="currentLocalIt.type_local_it !== 'Local informatique / Datacenter / Hébergement en propre'"
                  v-model="currentLocalIt.contrat"
                  field-label="Contrat" 
                  label="Liste des contrats"
                  :items="localItEnums.contrat_list"
                  field-description="Préciser le contrat vous liant à votre hébergement"
                  class="mb-3"
                />
                <form-fields-text
                  v-if="currentLocalIt.contrat === 'Autre'"
                  v-model="currentLocalIt.otherContrat"
                  field-label="Pouvez-vous préciser lequel ?"
                  placeholder="Préciser le contrat vous liant à votre hébergement"
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-textarea
                  v-model="currentLocalIt.commentaire"
                  field-label="Commentaires" 
                  placeholder="Commentaires"
                  type="textarea"
                  @blur="updateCurrentLocalIt"
                />  
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="form-expansion-panel">
              <v-expansion-panel-header color="cassiopee-purple--text text-h1 pl-3">
                Connectivité
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <form-fields-multi-select 
                  v-model="currentLocalIt.it_operateur_ids"
                  field-label="Opérateurs présents" 
                  label="Liste des opérateurs"
                  :items="itOperateursSelectList"
                  item-text="nom"
                  item-value="id"
                  field-description="Lister les opérateurs présents"
                  multiple
                  class="mb-3 mt-6"
                  @change="updateCurrentLocalIt(); fetchLocalIts()"
                />
                <div
                  v-for="(autre_operateur, index) in otherItOperateurs"
                  :key="index"
                >
                  <v-col 
                    cols="12" 
                    class="d-flex px-0 justify-end" 
                  >
                    <form-fields-text
                      v-model="autre_operateur.it_operateur.nom_autre"
                      field-label="Pouvez-vous préciser le nom de l'opérateur ?"
                      placeholder="Préciser le nom de l'opérateur"
                      class="mb-3"
                      @blur="updateItOperateur({itOperateur: autre_operateur.it_operateur})"
                    />
                    <base-button-icon 
                      icon="$trashCan"
                      class="d-flex align-start"
                      @click="removeItOperateur({localItId: currentLocalIt.id, itOperateur: autre_operateur})"
                    />
                  </v-col>
                </div>
                <form-button-add-element
                  label="Ajouter un opérateur" 
                  outlined
                  @click="createItOperateur(currentLocalIt.id)" 
                />
                <div 
                  v-for="local_it_operateur in localItOperateurs"
                  :key="local_it_operateur.id"
                >
                  <form-fields-multi-select 
                    v-model="local_it_operateur.type_connectivite_ids"
                    :field-label="`Quel type de connectivité utilisez vous auprès de ${ local_it_operateur.it_operateur.nom_autre ? local_it_operateur.it_operateur.nom_autre : local_it_operateur.it_operateur.nom } ?`" 
                    label="Liste des types de connectivité"
                    :items="typeConnectivites"
                    item-text="nom"
                    item-value="id"
                    field-description="Pour chaque opérateur sélectionné précédemment, quel est le type de connectivité souscrit (choix multiple possible)"
                    multiple
                    class="mb-3"
                    @change="updateLocalItItOperateur({ localItId: currentLocalIt.id, localItItOperateurId: local_it_operateur.id })"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel 
              v-if="currentLocalIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre' || currentLocalIt.type_local_it === 'Housing / Colocation'"
              class="form-expansion-panel"
            >
              <v-expansion-panel-header color="cassiopee-purple--text text-h1 pl-3">
                Capacité et fiabilité physiques (Brick and Mortar)
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <base-subtitle>
                  Surface
                </base-subtitle>
                <form-fields-text
                  v-model="currentLocalIt.capacite_fiab_surface_occupee_hebergement"
                  field-label="Surface de la salle informatique"
                  placeholder="Surface de la salle informatique"
                  input-suffix="m2"
                  class="mt-6"
                  type="number"
                  number-integer
                  required
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-text
                  v-model="currentLocalIt.capacite_fiab_surface_occupee_tiers"
                  field-label="Surface supplémentaire occupée par les locaux techniques, logistiques et tertiaires dédiés à la salle informatique"
                  placeholder="Surface supplémentaire occupée par les différents locaux"
                  input-suffix="m2"
                  type="number"
                  number-integer
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-text
                  v-model="currentLocalIt.capacite_fiab_nombre_baie"
                  field-label="Capacité en nombre de baies"
                  placeholder="Capacité en nombre de baies"
                  type="number"
                  input-suffix="baies"
                  number-integer
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-text
                  v-model="currentLocalIt.nombre_baies_utilisees"
                  field-label="Nombre de baies utilisées"
                  placeholder="Nombre de baies"
                  input-suffix="baies"
                  type="number"
                  number-integer
                  @blur="updateCurrentLocalIt"
                />
                <base-subtitle subtitle="Electricité" />
                <form-fields-text
                  v-model="currentLocalIt.capacite_fiab_nombre_kw_actuel"
                  field-label="Capacité en nombre de kW IT"
                  placeholder="Capacité en nombre de kW IT"
                  field-description="Exprimer les valeurs en kW. 1 kVa = 0,9 kW (environ). Préciser la Puissance disponible maximale (kW IT) pour vos équipements informatiques."
                  input-suffix="kW"
                  type="number"
                  number-integer
                  class="mb-3"
                  @blur="updateCurrentLocalIt"
                />
                <!-- <form-fields-text
                  v-model="currentLocalIt.taux_utilisation_puissance"
                  field-label="Taux d'utilisation de la puissance (utilisée sur total)"
                  placeholder="Taux d'utilisation"
                  input-suffix="%"
                  type="number"
                  number-integer
                  @blur="updateCurrentLocalIt"
                /> -->
                <form-fields-text-and-switch 
                  v-model="currentLocalIt.exploit_interne_electricite_alimentation_it"
                  :switch-value="currentLocalIt.exploit_interne_electricite_alimentation_it_unite"
                  text-label="Electricité alimentation IT"
                  placeholder="Electricité alimentation IT"
                  text-field-hint="Préciser uniquement l'électricité consommée par les équipements informatiques"
                  switch-label="kW"
                  false-value="kWh/an"
                  true-value="kW"
                  switch-prepend-label="kWh/an"
                  number-integer
                  class="mb-3"
                  @blur="updatePueFields('alimentationIt') ; updateCurrentLocalIt()"
                  @switchChange="currentLocalIt.exploit_interne_electricite_alimentation_it_unite = $event; updatePueFields() ; updateCurrentLocalIt()"
                />
                <form-fields-text-and-switch 
                  v-model="currentLocalIt.exploit_interne_electricite_alimentation_datacenter"
                  :switch-value="currentLocalIt.exploit_interne_electricite_alimentation_datacenter_unite"
                  text-label="Electricité alimentation Datacenter (IT + Clim + servitudes)"
                  placeholder="Electricité alimentation Datacenter"
                  switch-label="kW"
                  false-value="kWh/an" 
                  true-value="kW"
                  number-integer
                  switch-prepend-label="kWh/an"
                  @blur="updatePueFields('alimentationDatacenter') ; updateCurrentLocalIt()"
                  @switchChange="currentLocalIt.exploit_interne_electricite_alimentation_datacenter_unite = $event; updatePueFields(); updateCurrentLocalIt()"
                />
                <base-commentaire>
                  Préciser la consommation pour tous équipements du local IT ou Datacenter : IT , climatisation, éclairage, chauffage de locaux dédiés au datacenter, etc.
                </base-commentaire>
                <form-fields-slider
                  v-model="currentLocalIt.exploit_interne_efficacite_energetique_pue"
                  field-label="PUE (efficacité énergétique)" 
                  hint="Précisez, si vous le connaissez, le PUE de votre salle informatique. PUE = kWh Datacenter / kWh IT"
                  persistent-hint
                  readonly
                  min="1"
                  max="5"
                  :step="0.1"
                  :currency="false"
                  @change="updatePueFields('pue') ; updateCurrentLocalIt()"
                />
                <base-subtitle>
                  Niveau de service
                </base-subtitle>
                <v-row>
                  <v-col 
                    cols="4" 
                    class="d-flex align-center"
                  >
                    <form-fields-label label="Niveau de service actuel" />
                  </v-col>
                  <v-col 
                    cols="8" 
                    class="d-flex align-center"
                  >
                    <form-fields-multi-select 
                      v-model="currentLocalIt.capacite_fiab_niveau_service_actuel"
                      label="Niveau de service"
                      :items="niveauxServices"
                      class="mt-5"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <form-fields-radio-group 
                  v-model="currentLocalIt.capacite_fiab_double_alimentation_choice"
                  field-label="Double alimentation électrique depuis l'arrivée du site" 
                  :radio-data-list="booleanList"
                  @change="updateCurrentLocalIt"
                />
                <form-fields-radio-group 
                  v-model="currentLocalIt.capacite_fiab_secours_electrique_choice"
                  field-label="Secours électrique (par groupe électrogène)" 
                  :radio-data-list="booleanList"
                  @change="updateCurrentLocalIt"
                />
                <form-fields-text
                  v-if="currentLocalIt.capacite_fiab_secours_electrique_choice === 'Oui'"
                  v-model="currentLocalIt.capacite_fiab_secours_electrique_duree"
                  field-label="Autonomie fioul du secours électrique"
                  placeholder="Durée en heures"
                  type="number"
                  number-integer
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-radio-group 
                  v-if="currentLocalIt.capacite_fiab_secours_electrique_choice === 'Oui'"
                  v-model="currentLocalIt.capacite_fiab_secours_electrique_test_fonctionnement_choice"
                  field-label="Tests de vérification du bon fonctionnement" 
                  hint="Recommandé : 1 démarrage à vide par mois et 1 test en charge par an"
                  persistent-hint
                  class="mb-2"
                  :radio-data-list="booleanList"
                  @change="updateCurrentLocalIt"
                />
                
                <form-fields-radio-group 
                  v-model="currentLocalIt.capacite_fiab_redond_onduleurs_choice"
                  field-label="Redondance des onduleurs" 
                  :radio-data-list="booleanList"
                  @change="updateCurrentLocalIt"
                />
                <form-fields-text
                  v-model="currentLocalIt.capacite_fiab_redond_onduleurs_duree"
                  field-label="En cas de coupure électrique, combien de temps vos onduleurs maintiennent l'informatique de la salle/local"
                  placeholder="Durée en minutes"
                  type="number"
                  number-integer
                  input-suffix="minutes"
                  @blur="updateCurrentLocalIt"
                />
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.capacite_fiab_redond_tableaux_generaux_distrib_choice"
                      min="0"
                      max="10"
                      field-label="Distribution électrique" 
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.capacite_fiab_redond_production_froid_choice"
                      min="0"
                      max="10"
                      field-label="Production de froid" 
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.capacite_fiab_redond_distrib_froid_choice"
                      min="0"
                      max="10"
                      field-label="Distribution du froid" 
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.capacite_fiab_presence_detection_incendie_choice"
                      min="0"
                      max="10"
                      field-label="Système de détection incendie" 
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.capacite_fiab_presence_systeme_extinction_incendie_choice"
                      min="0"
                      max="10"
                      field-label="Système d'extinction incendie automatique" 
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <form-fields-radio-group 
                  v-model="currentLocalIt.capacite_fiab_presence_systeme_detection_eau_choice"
                  field-label="Equipé d'un système de détection d'eau" 
                  :radio-data-list="booleanList"
                  @change="updateCurrentLocalIt"
                />
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.adduction_reseau"
                      min="0"
                      max="10"
                      field-label="Adduction réseau" 
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.capacite_fiab_presence_systeme_supervision_infras_tech_choice"
                      min="0"
                      max="10"
                      field-label="Système de supervision des infrastructures techniques" 
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.risque_capacites_hebergement"
                      min="0"
                      max="10"
                      field-label="Capacités d'hébergement" 
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <form-fields-textarea
                  v-model="currentLocalIt.capacite_fiab_commentaire"
                  field-label="Commentaires" 
                  placeholder="Commentaires"
                  type="textarea"
                  @blur="updateCurrentLocalIt"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel 
              v-if="currentLocalIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre' || currentLocalIt.type_local_it === 'Housing / Colocation' || currentLocalIt.type_local_it === 'IAAS / PAAS'"
              class="form-expansion-panel"
            >
              <v-expansion-panel-header color="cassiopee-purple--text text-h1 pl-3">
                Capacités serveurs (physiques et virtuelles)
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-5">
                  <v-col cols="4">
                    <form-fields-label label="Nombre de serveurs physiques" />
                  </v-col>
                  <v-col cols="4">
                    <span class="text-h3">Actuel:</span>
                    <form-fields-text
                      v-model="currentLocalIt.capacite_serveurs_nombre_serveurs_actuel"
                      placeholder="Nombre de serveurs physiques actuel"
                      type="number"
                      number-integer
                      class="mt-1"
                      @blur="updateCurrentLocalIt"
                    />
                  </v-col>
                  <v-col cols="4">
                    <span class="mr-2 text-h3">Potentiel:</span>
                    <form-fields-text
                      v-model="currentLocalIt.capacite_serveurs_nombre_serveurs_potentiel"
                      placeholder="Nombre de serveurs physiques potentiel"
                      type="number"
                      number-integer
                      class="mt-1"
                      @blur="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <form-fields-label label="Nombre de VM" />
                  </v-col>
                  <v-col cols="4">
                    <span class="text-h3">Actuel:</span>
                    <form-fields-text
                      v-model="currentLocalIt.capacite_serveurs_nombre_machine_virtuelle_actuel"
                      placeholder="Nombre de VM actuel"
                      type="number"
                      number-integer
                      class="mt-1"
                      @blur="updateCurrentLocalIt"
                    />
                  </v-col>
                  <v-col cols="4">
                    <span class="text-h3">Potentiel:</span>
                    <form-fields-text
                      v-model="currentLocalIt.capacite_serveurs_nombre_machine_virtuelle_potentiel"
                      placeholder="Nombre de VM potentiel"
                      type="number"
                      number-integer
                      class="mt-1"
                      @blur="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <form-fields-label label="Capacité de stockage/sauvegarde" />
                  </v-col>
                  <v-col cols="4">
                    <span class="text-h3">Actuel:</span>
                    <form-fields-text
                      v-model="currentLocalIt.capacite_serveurs_capacite_stockage_disque_actuel"
                      placeholder="Nombre de To actuel"
                      input-suffix="To"
                      type="number"
                      number-decimal
                      class="mt-1"
                      @blur="updateCurrentLocalIt"
                    />
                  </v-col>
                  <v-col cols="4">
                    <span class="text-h3">Potentiel:</span>
                    <form-fields-text
                      v-model="currentLocalIt.capacite_serveurs_capacite_stockage_disque_potentiel"
                      placeholder="Nombre de To potentiel"
                      input-suffix="To"
                      type="number"
                      number-decimal
                      class="mt-1"
                      @blur="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <form-fields-text
                  v-model="currentLocalIt.age_moyen_infrastructures"
                  field-label="Age moyen de vos infrastructures informatiques" 
                  label="Age moyen de vos infrastructures informatiques"
                  type="number"
                  input-suffix="ans"
                  number-decimal
                  @change="updateCurrentLocalIt"
                />
                <form-fields-textarea
                  v-model="currentLocalIt.capacite_serveurs_commentaire"
                  field-label="Commentaires"
                  placeholder="Commentaires" 
                  type="textarea"
                  @blur="updateCurrentLocalIt"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              v-if="currentLocalIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre'"
              class="form-expansion-panel"
            > 
              <v-expansion-panel-header color="cassiopee-purple--text text-h1 pl-3">
                Données financières
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <base-subtitle 
                  subtitle="Foncier" 
                  class="mt-6"
                />
                <form-fields-radio-group 
                  v-model="currentLocalIt.exploit_interne_foncier"
                  field-label="Foncier" 
                  simple-radio-button
                  :radio-data-list="foncierList"
                  @change="updateCurrentLocalIt"
                />
                <form-fields-date 
                  v-if="currentLocalIt.exploit_interne_foncier === 'Achat'"
                  v-model="currentLocalIt.exploit_interne_foncier_date_achat_terrain"
                  field-label="Année achat terrain/bâtiment" 
                  hint="format YYYY/MM"
                  year
                  class="mb-6"
                  @input="updateCurrentLocalIt"
                />
                <form-fields-text-and-switch
                  v-if="currentLocalIt.exploit_interne_foncier === 'Achat'"
                  v-model="currentLocalIt.exploit_interne_foncier_montant_achat_terrain"
                  :switch-value="currentLocalIt.exploit_interne_foncier_montant_achat_terrain_ht"
                  text-label="Montant"
                  placeholder="Montant de l'achat terrain/bâtiment"
                  number-integer
                  suffix="k€"
                  @blur="updateCurrentLocalIt"
                  @switchChange="currentLocalIt.exploit_interne_foncier_montant_achat_terrain_ht = $event ; updateCurrentLocalIt()"
                />
                <form-fields-text
                  v-if="currentLocalIt.exploit_interne_foncier === 'Achat'"
                  v-model="currentLocalIt.exploit_interne_foncier_amortissement_montant_annuel"
                  field-label="Durée"
                  placeholder="Durée"
                  input-suffix="ans"
                  number-integer
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-text-and-switch
                  v-if="currentLocalIt.exploit_interne_foncier === 'Location'"
                  v-model="currentLocalIt.exploit_interne_foncier_loyer_montant_annuel"
                  text-label="Montant"
                  placeholder="Montant du loyer"
                  :switch-value="currentLocalIt.exploit_interne_foncier_loyer_ht"
                  suffix="€/an"
                  number-integer
                  @blur="updateCurrentLocalIt"
                  @switchChange="currentLocalIt.exploit_interne_foncier_loyer_ht = $event ; updateCurrentLocalIt()"
                />
                <base-subtitle 
                  subtitle="Taxe immobilière / impôts fonciers" 
                />
                <form-fields-text-and-switch 
                  v-model="currentLocalIt.exploit_interne_taxe_immo_impot_foncier_montant_annuel"
                  :switch-value="currentLocalIt.exploit_interne_taxe_immo_impot_foncier_montant_annuel_ht"
                  text-label="Préciser le coût (€/an) pour la gestion"
                  placeholder="Euro/an"
                  number-integer
                  suffix="€/an"
                  class="mb-6"
                  @switchChange="currentLocalIt.exploit_interne_taxe_immo_impot_foncier_montant_annuel_ht = $event; updateCurrentLocalIt()"
                  @blur="updateCurrentLocalIt"
                />
                <base-subtitle 
                  subtitle="Assurance" 
                />
                <form-fields-text-and-switch 
                  v-model="currentLocalIt.exploit_interne_assurance_montant_annuel"
                  :switch-value="currentLocalIt.exploit_interne_assurance_montant_annuel_ht"
                  text-label="Préciser le coût (€/an) pour la gestion"
                  placeholder="Euro/an"
                  number-integer
                  suffix="€/an"
                  class="mb-6"
                  @switchChange="currentLocalIt.exploit_interne_assurance_montant_annuel_ht = $event; updateCurrentLocalIt()"
                  @blur="updateCurrentLocalIt"
                >
                  <!-- <form-fields-text 
                    v-model="currentLocalIt.exploit_interne_assurance_etp_annuel"
                    class="px-0"
                    placeholder="ETP/an"
                    type="number"
                    input-suffix="ETP/an"
                    number-decimal
                    @blur="updateCurrentLocalIt"
                  /> -->
                </form-fields-text-and-switch>
                <base-subtitle 
                  subtitle="Investissements" 
                  commentaire="Préciser les travaux impliquant une remise aux normes ou à l'état de l'art de la salle en précisant l'année et le coût. Exemples : Travaux infrastructures techniques élec, clim, sécurité / bâtiment, Mise en oeuvre infrastructures réseau, Urbanisation (baies, PDU, STS, réseau interne datacenter)"
                />
                <div
                  v-for="(chantier, index) in currentLocalIt.projet_travaux"
                  :key="index"
                >
                  <form-fields-text 
                    v-model="chantier.nom"
                    :field-label="`Investissement ${index+1}`" 
                    placeholder="Investissement"
                    icon="$trashPurple"
                    @click:append-outer="removeTravauxFromList(chantier.id)" 
                    @blur="updateCurrentInvestment(chantier)"
                  />
                  <v-row class="justify-end">
                    <v-col cols="2">
                      <form-fields-text 
                        v-model="chantier.annee"
                        field-label="Année" 
                        type="number"
                        number-integer
                        placeholder="Année de l'investissement"
                        @blur="updateCurrentInvestment(chantier)"
                      />
                    </v-col>
                    <v-col cols="6">
                      <form-fields-text-and-switch
                        v-model="chantier.cout_total"
                        text-label="Montant"
                        placeholder="Montant"
                        :switch-value="chantier.cout_total_ht"
                        suffix="k€"
                        number-integer
                        centered
                        @blur="updateCurrentInvestment(chantier)"
                        @switchChange="chantier.cout_total_ht = $event ; updateCurrentInvestment(chantier)"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="justify-end">
                    <v-col 
                      cols="4" 
                      class="d-flex align-center"
                    >
                      <base-switch
                        :input-value="chantier.presence_amortissement"
                        label="Pas d'amortissement"
                        :false-value="true"
                        :true-value="false"
                        prepend-label="Amortissement"
                        @change="chantier.presence_amortissement = $event ; updateCurrentInvestment(chantier)"
                      />
                    </v-col>
                    <v-col cols="4">
                      <form-fields-text 
                        v-if="chantier.presence_amortissement"
                        v-model="chantier.duree_amortissement"
                        placeholder="Durée de l'amortissement"
                        input-suffix="an"
                        type="number"
                        @blur="updateCurrentInvestment(chantier)"
                      />
                    </v-col>
                  </v-row>
                </div>
                <form-button-add-element 
                  class="d-flex justify-end mb-5"
                  label="Ajouter un nouvel investissement"
                  outlined
                  @click="createNewtravaux()"
                />
                <v-row 
                  v-for="element in elementList" 
                  :key="element.id"
                >
                  <v-col>
                    <base-subtitle 
                      :subtitle="element.label" 
                      class="my-3"
                    />
                    <div>
                      <form-fields-text-and-switch
                        v-if="element.noEtp"
                        v-model="currentLocalIt[`exploit_interne_${element.backendField}_montant_annuel`]"
                        :switch-value="currentLocalIt[`exploit_interne_${element.backendField}_montant_annuel_ht`]"
                        text-label="Préciser le coût (€/an) pour la gestion (uniquement la part dédiée à ce local IT)."
                        :commentaire="element.hint || 'Uniquement la part dédiée au local informatique.'"
                        placeholder="Euro/an"
                        number-integer
                        suffix="€/an"
                        @switchChange="currentLocalIt[`exploit_interne_${element.backendField}_montant_annuel_ht`] = $event; updateCurrentLocalIt()"
                        @blur="updateCurrentLocalIt"
                      />

                      <base-multi-field
                        v-else
                        v-model="currentLocalIt[`exploit_interne_${element.backendField}_montant_annuel`]"
                        :switch-value="currentLocalIt[`exploit_interne_${element.backendField}_montant_annuel_ht`]"
                        label="Préciser le coût (€/an) pour la gestion (uniquement la part dédiée à ce local IT)."
                        :commentaire="element.hint || 'Uniquement la part dédiée au local informatique.'"
                        placeholder="Euro/an"
                        number-integer
                        suffix="€/an"
                        @switchChange="currentLocalIt[`exploit_interne_${element.backendField}_montant_annuel_ht`] = $event; updateCurrentLocalIt()"
                        @blur="updateCurrentLocalIt"
                      >
                        <form-fields-text 
                          v-model="currentLocalIt[`exploit_interne_${element.backendField}_etp_annuel`]"
                          class="px-0"
                          placeholder="ETP/an"
                          type="number"
                          input-suffix="ETP/an"
                          number-decimal
                          @blur="updateCurrentLocalIt"
                        />
                      </base-multi-field>
                    </div>
                  </v-col>
                </v-row>
                <form-fields-textarea
                  v-model="currentLocalIt.exploit_interne_commentaire"
                  field-label="Commentaires"
                  placeholder="Commentaires" 
                  type="textarea"
                  class="mt-8"
                  @blur="updateCurrentLocalIt"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel 
              v-if="currentLocalIt.type_local_it === 'Housing / Colocation' || currentLocalIt.type_local_it === 'IAAS / PAAS' || currentLocalIt.type_local_it === 'Service SAAS'"
              class="form-expansion-panel"
            >
              <v-expansion-panel-header color="cassiopee-purple--text text-h1 pl-3">
                Données financières
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-8">
                <form-fields-text-and-switch
                  v-if="currentLocalIt.type_local_it === 'Housing / Colocation'"
                  v-model="currentLocalIt.exploit_externe_cloud_loyer_hebergement_housing"
                  :switch-value="currentLocalIt.exploit_externe_cloud_loyer_hebergement_housing_ht"
                  text-label="Loyer d'hébergement Housing"
                  placeholder="Loyer d'hébergement Housing coût €/an"
                  type="number"
                  number-integer
                  @switchChange="currentLocalIt.exploit_externe_cloud_loyer_hebergement_housing_ht = $event; updateCurrentLocalIt()"
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-text-and-switch
                  v-if="currentLocalIt.type_local_it === 'Housing / Colocation'"
                  v-model="currentLocalIt.exploit_externe_cloud_cout_energie"
                  :switch-value="currentLocalIt.exploit_externe_cloud_cout_energie_ht"
                  text-label="Coût de l'énergie lié à l'hébergement housing"
                  placeholder="Energie coût €/an"
                  text-field-hint="Préciser le coût s'il n'est pas déjà inclus dans le loyer d'hébergement."
                  type="number"
                  number-integer
                  class="mb-2"
                  @switchChange="currentLocalIt.exploit_externe_cloud_cout_energie_ht = $event; updateCurrentLocalIt()"
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-text-and-switch
                  v-if="currentLocalIt.type_local_it === 'Housing / Colocation'"
                  v-model="currentLocalIt.exploit_externe_cloud_cout_geste_proximite"
                  :switch-value="currentLocalIt.exploit_externe_cloud_cout_geste_proximite_ht"
                  text-label="Gestes de proximité"
                  placeholder="Gestes de proximité coût €/an"
                  type="number"
                  number-integer
                  @switchChange="currentLocalIt.exploit_externe_cloud_cout_geste_proximite_ht = $event; updateCurrentLocalIt()"
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-text-and-switch
                  v-model="currentLocalIt.exploit_externe_cloud_cout_lien_telecoms"
                  :switch-value="currentLocalIt.exploit_externe_cloud_cout_lien_telecoms_ht"
                  text-label="Lien télécoms"
                  placeholder="Lien télécoms coût €/an"
                  text-field-hint="Coût lié à la bande passante réseau"
                  class="mb-1"
                  type="number"
                  number-integer
                  @switchChange="currentLocalIt.exploit_externe_cloud_cout_lien_telecoms_ht = $event; updateCurrentLocalIt()"
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-text-and-switch
                  v-model="currentLocalIt.exploit_externe_cloud_cout_service_informatique_infogerance"
                  :switch-value="currentLocalIt.exploit_externe_cloud_cout_service_informatique_infogerance_ht"
                  text-label="Coût des services informatiques d'infogérance"
                  placeholder="Services informatique d'infogérance coût €/an"
                  type="number"
                  number-integer
                  class="mb-2"
                  @switchChange="currentLocalIt.exploit_externe_cloud_cout_service_informatique_infogerance_ht = $event; updateCurrentLocalIt()"
                  @blur="updateCurrentLocalIt"
                />
                <form-fields-text-and-switch
                  v-if="currentLocalIt.type_local_it === 'IAAS / PAAS' || currentLocalIt.type_local_it === 'Service SAAS'"
                  v-model="currentLocalIt.exploit_externe_cloud_cout_service"
                  :switch-value="currentLocalIt.exploit_externe_cloud_cout_service_ht"
                  text-label="Coût des services à l'usage (cloud)"
                  placeholder="Services à l'usage (cloud) coût €/an"
                  text-field-hint="Préciser le coût annuel de votre hébergement type IAAS, PAAS ou SAAS."
                  type="number"
                  class="mb-3"
                  number-integer
                  @switchChange="currentLocalIt.exploit_externe_cloud_cout_service_ht = $event; updateCurrentLocalIt()"
                  @blur="updatePartHebergementFields(); updateCurrentLocalIt()"
                />
                <v-row class="mb-3">
                  <v-col cols="4">
                    <form-fields-label
                      label="Part de l'hébergement sec (datacenter)"
                      commentaire="Indiquer la part de l'hébergement dans le montant que vous payez à votre prestataire d'hébergement"
                    />
                  </v-col>
                  <v-col cols="4">
                    <form-fields-text
                      v-model="currentLocalIt.exploit_externe_cloud_part_hebergement_sec_pct_contrat_global"
                      placeholder="Coût en pourcentage du contrat global"
                      input-suffix="%"
                      type="number"
                      number-integer
                      @blur="updatePartHebergementFields(); updateCurrentLocalIt()"
                    />
                  </v-col>
                  <v-col 
                    cols="4" 
                    class="pr-0"
                  >
                    <form-fields-text
                      v-model="currentLocalIt.exploit_externe_cloud_part_hebergement_sec_cout_annuel"
                      type="number"
                      input-suffix="€/an"
                      readonly
                      @blur="updatePartHebergementFields(); updateCurrentLocalIt()"
                    />
                  </v-col>
                </v-row>
                <base-multi-field 
                  v-model="currentLocalIt.exploit_externe_gestion_prestataire_cout_annuel"
                  :switch-value="currentLocalIt.exploit_externe_gestion_prestataire_cout_annuel_ht"
                  label="Gestion du prestataire"
                  placeholder="Gestion du prestataire coût €/an"
                  commentaire="Préciser le coût annuel pour gérer le prestataire. Cela inclut le process de sélection d'un prestataire (cahier des charges, appel d'offre), le suivi de la prestation."
                  number-integer
                  suffix="€/an"
                  class="mb-6"
                  @switchChange="currentLocalIt.exploit_externe_gestion_prestataire_cout_annuel_ht = $event; updateCurrentLocalIt()"
                  @blur="updateCurrentLocalIt"
                >
                  <form-fields-text
                    v-model="currentLocalIt.exploit_externe_cout_gestion_prestataire_etp_annuel"
                    placeholder="Gestion du prestataire coût ETP/an"
                    type="number"
                    number-decimal
                    class="px-0"
                    input-suffix="ETP/an"
                    @blur="updateCurrentLocalIt"
                  />
                </base-multi-field>
                <form-fields-textarea
                  v-model="currentLocalIt.exploit_externe_commentaire"
                  field-label="Commentaires"
                  placeholder="Commentaires" 
                  type="textarea"
                  @blur="updateCurrentLocalIt"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel 
              v-if="currentLocalIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre' || currentLocalIt.type_local_it === 'Housing / Colocation'"
              class="form-expansion-panel"
            >
              <v-expansion-panel-header color="cassiopee-purple--text text-h1 pl-3">
                Risques bâtiment et implantation
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.risque_environnement_naturel"
                      min="0"
                      max="10"
                      field-label="Risque lié à l'Environnement naturel"
                      hint="Inondation ; Mouvement de terrain ; Sismique, tsunami ; Vent, tornade, ouragan ; Orage, foudre ;  Feu externe au bâtiment ; Volcanique"
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.risque_environnement_technologique"
                      min="0"
                      max="10"
                      field-label="Risque lié à l'Environnement technologique"
                      hint="Risque nucléaire ;  Chute d'avion ; Industriel (inflammable, toxique, explosif, Seveso) ;  Transport matières dangereuses ; Région métropolitaine ;  Vibration ;  électromagnétique ; Autres activités sur place"
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.risque_environnement_societal"
                      min="0"
                      max="10"
                      field-label="Risque lié à Environnement sociétal"
                      hint="Risque humain de masse (vandalisme, sabotage)  ; Inaccessibilité"
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.risque_vetuste"
                      min="0"
                      max="10"
                      field-label="Vétusté"
                      hint="Ancienneté des installations techniques et bâtimentaires"
                      persistent-hint
                      class="mt-6"
                      :tick-labels="tickLabels"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.risque_controle_acces"
                      min="0"
                      max="10"
                      :tick-labels="tickLabels"
                      field-label="Contrôle d'accès"
                      hint="Type de contrôle, nbre de points de contrôle, contrôle de l'unicité de passage"
                      persistent-hint
                      class="mt-6"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.risque_intrusion"
                      min="0"
                      max="10"
                      :tick-labels="tickLabels"
                      field-label="Intrusion"
                      persistent-hint
                      class="mt-6"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mb-4">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.risque_systeme_videosurveillance"
                      min="0"
                      max="10"
                      :tick-labels="tickLabels"
                      field-label="Système de vidéosurveillance"
                      persistent-hint
                      class="mt-6"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-3 mb-6">
                  <v-col cols="12">
                    <form-fields-slider
                      v-model="currentLocalIt.risque_surveillance_du_site"
                      min="0"
                      max="10"
                      :tick-labels="tickLabels"
                      field-label="Surveillance du site"
                      hint="Gardiennage ; rondes ; astreintes"
                      persistent-hint
                      class="mt-6"
                      :currency="false"
                      @change="updateCurrentLocalIt"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <base-button
              width="440px"
              icon="$trashCan"
              @click="deleteCurrentLocalIt" 
            >
              Supprimer la ressource
            </base-button>
            <form-button-add-element 
              label="Ajouter une ressource" 
              outlined
              @click="addLocalIt" 
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FormStepTitle from '@/components/FormStepTitle.vue';
import FormButtonAddElement from '@/components/FormButtonAddElement.vue';
import FormAlert from '@/components/FormAlert.vue';
import FormFieldsText from '@/components/FormFieldsText.vue';
import FormFieldsTextAndSwitch from '@/components/FormFieldsTextAndSwitch.vue';
import FormFieldsSlider from '@/components/FormFieldsSlider.vue';
import FormFieldsMultiSelect from '@/components/FormFieldsMultiSelect.vue';
import FormFieldsRadioGroup from '@/components/FormFieldsRadioGroup.vue';
import BaseButton from '@/components/BaseButton.vue';
import FormFieldsTextarea from '@/components/FormFieldsTextarea.vue';
import FormFieldsDate from '@/components/FormFieldsDate.vue';
import BaseSubtitle from '@/components/BaseSubtitle.vue';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { mapMultiRowFields, mapFields } from 'vuex-map-fields';
import FormFieldsLabel from '@/components/FormFieldsLabel.vue';
import BaseMultiField from '@/components/BaseMultiField.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseCommentaire from '@/components/BaseCommentaire.vue';
import { some, filter, orderBy } from 'lodash';
import BaseButtonIcon from '@/components/BaseButtonIcon.vue';

export default {
  name: "SiteDefinition",
  components: {
    FormStepTitle,
    FormButtonAddElement,
    FormAlert,
    FormFieldsText,
    FormFieldsTextAndSwitch,
    FormFieldsSlider,
    FormFieldsMultiSelect,
    BaseButton,
    FormFieldsRadioGroup,
    FormFieldsTextarea,
    FormFieldsDate,
    BaseSubtitle,
    FormFieldsLabel,
    BaseMultiField,
    BaseSwitch,
    BaseCommentaire,
    BaseButtonIcon
  },
  data () {
    const tickLabels = ['Pas de risque'];
    tickLabels[10] = 'Très risqué';
    const otherTickLabels = ['À l\'état de l\'art'];
    otherTickLabels[10] = "Pas de sauvegarde";
    return {
      ressources_hebergement_complete: null,
      length: 1,
      currentTab: null,
      typeExploitantElectricite: null,
      typeExploitantBatiment: null,
      staffExploitationList: [
        'Service IT',
        'Autre',
      ],
      booleanList: [{
        label: 'Oui',
        value: 'Oui',
      }, {
        label: 'Non',
        value: 'Non',
      }, {
        label: 'Ne sait pas',
        value: 'Ne sait pas'
      }
      ],
      niveauxServices: [
        'TIER 1',
        'TIER 2',
        'TIER 3',
        'TIER 3+',
        'TIER 4',
        'Ne sait pas'
      ],
      foncierList: [
        {
          label: "En propre",
          value: "En propre"
        },
        {
          label: "Achat",
          value: "Achat"
        },
        {
          label: "Location",
          value: "Location"
        },
        {
          label: 'Ne sait pas',
          value: 'Ne sait pas'
        }
      ],
      elementList: [
        {
          label: 'Eau',
          backendField: 'eau',
          noEtp: true,
        },
        {
          label: 'Fioul',
          backendField: 'fioul',
          noEtp: true,
        },
        {
          label: 'Liens Télécoms',
          backendField: 'lien_telecoms'},
        {
          label: 'Gardiennage',
          backendField: 'gardiennage'},
        {
          label: 'Entretien',
          backendField: 'entretien',
          noEtp: true,
          hint: 'Ménage, petits travaux d’entretien bâtimentaire'
        },
        {
          label: 'Gestes de proximité',
          backendField: 'geste_proximite'},
        {
          label: 'Services informatique d\'infogérance',
          backendField: 'serv_informatique_infogerance'},
        {
          label: 'Responsable Datacenter',
          backendField: 'responsable_datacenter'},
        {
          label: 'Maintenance électricité',
          backendField: 'maintenance_electricite'},
        {
          label: 'Maintenance climatisation',
          backendField: 'maintenance_climatisation'},
        {
          label: 'Maintenance sécurité / sûreté',
          backendField: 'maintenance_securite'},
        {
          label: 'Personnel d\'exploitation',
          backendField: 'personnel_exploitation'},
        {
          label: 'Gestion clients (internes et/ou externes)',
          backendField: 'gestion_client'}
      ],
      tickLabels,
      otherTickLabels,
    }
  },
  computed: {
    ...mapGetters('form', ['itOperateursSelectList']),
    ...mapMultiRowFields('form', ['localIts', 'perimetre_travaux']),
    ...mapState('form', ['sites', 'typeLocalIts', 'localItEnums', 'entites', 'esrEntites', 'operateurs', 'typeConnectivites', 'entitesCreatedByFront']),
    currentLocalIt() {
      return this.localIts[this.currentTab] || {};
    },
    ...mapFields('form', [
      'formAnswer.niveau_completion_ressources_hebergement',
    ]),
    otherItOperateurs() {
      return orderBy(
        filter(
          this.currentLocalIt.local_it_it_operateurs,
          (local_it_it_operateur) => local_it_it_operateur.it_operateur.nom === 'Autre'
        ),
        () => ['id']
      );
    },
    localItOperateurs() {
      return filter(this.currentLocalIt.local_it_it_operateurs, (local_it_it_operateur) => local_it_it_operateur.it_operateur.nom !== 'Autre' || local_it_it_operateur.it_operateur.nom_autre)
    }
  },
  watch: {
    length (val) {
      this.currentTab = val - 1
    },
  },
  created() {
    this.fetchEntites();
    this.fetchOperateurs();
    this.fetchLocalItsEnums();
    this.fetchEsrEntites();
    this.fetchTypeConnectivites();
    this.fetchLocalIts().then(() => {
      if (this.currentLocalIt.exploitant_batiment === 'Service IT') {
        this.typeExploitantBatiment = 'Service IT';
      } else if (this.currentLocalIt.exploitant_batiment) {
        this.typeExploitantBatiment = 'Autre';
      }
      if (this.currentLocalIt.exploitant_electricite === 'Service IT') {
        this.typeExploitantElectricite = 'Service IT';
      } else if (this.currentLocalIt.exploitant_electricite) {
        this.typeExploitantElectricite = 'Autre';
      }
    });
    this.fetchSites();
    this.setNextView('/needs/security-referential');
    this.fetchPerimetreTravaux();
    this.fetchEntitesCreatedByFront();
    this.fetchFormAnswer();
  },
  methods: {
    ...mapMutations('stepper', ['setNextView']),
    ...mapActions('form', [
      'fetchLocalIts',
      'createLocalIt',
      'updateLocalIt',
      'fetchSites',
      'deleteLocalIt',
      'fetchEntites',
      'fetchEsrEntites',
      'fetchLocalItsEnums',
      'fetchOperateurs',
      'fetchTypeConnectivites',
      'updateLocalItItOperateur',
      'updateLocalItEntiteHebergee',
      'fetchPerimetreTravaux',
      'updateTravaux',
      'removeTravaux',
      'createTravaux',
      'uploadListeServeursAction',
      'addNewEntiteAutre',
      'fetchEntitesCreatedByFront',
      'createEntiteCreatedByFront',
      'deleteEntite',
      'updateEntiteCreatedByFront',
      'saveFormAnswer',
      'fetchFormAnswer',
      'removeItOperateur',
      'createItOperateur',
      'updateItOperateur'
    ]),
    some,
    addLocalIt() {
      this.createLocalIt().then(() => {
        this.currentTab = this.localIts.length - 1;
      });
    },
    updateCurrentLocalIt() {
      this.updateLocalIt(this.currentLocalIt.id);
    },
    deleteCurrentLocalIt() {
      if (confirm('Êtes-vous sûr de vouloir supprimer ce local, cette action est irréversible.')) {
        this.deleteLocalIt(this.currentLocalIt.id);
        this.currentTab -= 1;
      }
    },
    processExploitantBatiment(value) {
      if (value === 'Autre') {
        this.currentLocalIt.exploitant_batiment = '';
      } else {
        this.currentLocalIt.exploitant_batiment = value;
      }
      this.updateCurrentLocalIt();
    },
    processExploitantElectricite(value) {
      if (value === 'Autre') {
        this.currentLocalIt.exploitant_electricite = '';
      } else {
        this.currentLocalIt.exploitant_electricite = value;
      }
      this.updateCurrentLocalIt();
    },
    updateCurrentInvestment(chantier) {
      this.updateTravaux(chantier);
    },
    removeTravauxFromList(chantierId) {
      this.removeTravaux({ travauxId: chantierId, currentLocalIt: this.currentLocalIt });
    },
    createNewtravaux() {
      this.createTravaux(this.currentLocalIt.id)
    },
    uploadListeServeurs(file) {
      this.uploadListeServeursAction({ localItId: this.currentLocalIt.id, file: file });
    },
    updatePueFields(trigger) {
      const pue = this.currentLocalIt.exploit_interne_efficacite_energetique_pue;
      let alimentationIt = this.currentLocalIt.exploit_interne_electricite_alimentation_it;
      let alimentationDatacenter = this.currentLocalIt.exploit_interne_electricite_alimentation_datacenter;
      
      if (this.currentLocalIt.exploit_interne_electricite_alimentation_it_unite === 'kWh/an') {
        alimentationIt = alimentationIt / 8760;
      }
      if (this.currentLocalIt.exploit_interne_electricite_alimentation_datacenter_unite === 'kWh/an') {
        alimentationDatacenter = alimentationDatacenter / 8760;
      }

      if (alimentationIt && alimentationDatacenter && trigger !== 'pue') {
        this.currentLocalIt.exploit_interne_efficacite_energetique_pue = alimentationDatacenter / alimentationIt;
      } else if (alimentationDatacenter) {
        if (this.currentLocalIt.exploit_interne_electricite_alimentation_it_unite === 'kW') {
          this.currentLocalIt.exploit_interne_electricite_alimentation_it = alimentationDatacenter / pue;
        } else {
          this.currentLocalIt.exploit_interne_electricite_alimentation_it = alimentationDatacenter / pue * 8760;
        }
      } else if (alimentationIt) {
        if (this.currentLocalIt.exploit_interne_electricite_alimentation_datacenter_unite === 'kW') {
          this.currentLocalIt.exploit_interne_electricite_alimentation_datacenter = pue * alimentationIt;
        } else {
          this.currentLocalIt.exploit_interne_electricite_alimentation_datacenter = pue * alimentationIt * 8760;
        }
      }
    },
    updatePartHebergementFields() {
      const partHebergement = this.currentLocalIt.exploit_externe_cloud_part_hebergement_sec_pct_contrat_global / 100
      const coutCloudService = this.currentLocalIt.exploit_externe_cloud_cout_service
      this.currentLocalIt.exploit_externe_cloud_part_hebergement_sec_cout_annuel = partHebergement * coutCloudService 
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/variables';

.site-definition-tabs {
  border-bottom: 1px solid map-deep-get($colors, 'cassiopee-grey', 'base') !important;
}

.form-expansion-panel {
  background-color:  map-deep-get($colors, 'cassiopee-grey', 'lighten-1') !important;
  border-bottom: 1px solid map-deep-get($colors, 'cassiopee-purple', 'base') !important;
}

.v-expansion-panel-header {
  padding: 0px !important;
}

.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>