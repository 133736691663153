<template>
  <base-form-field 
    :label="fieldLabel" 
    :commentaire="commentaire"
  >
    <v-dialog
      ref="dialog"
      v-model="openModal"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <template #activator="{ on }">
        <v-text-field
          v-bind="$attrs"
          prepend-icon="mdi-calendar"
          readonly
          class="form-input-date text-subtitle-2"
          persistent-hint
          color="cassiopee-purple"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        color="cassiopee-purple"
        :show-current="false"
        :first-day-of-week="1"
        locale="fr"
        v-bind="$attrs"
        type="month"
        scrollable
      >
        <v-spacer />
        <v-btn
          text
          color="cassiopee-purple"
          @click="openModal = false"
        >
          Annuler
        </v-btn>
        <v-btn
          text
          color="cassiopee-purple"
          @click="$refs.dialog.save(date); emitClick()"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </base-form-field>
</template>

<script>
import BaseFormField from "./BaseFormField"

export default {
  name: "FormFieldsDate",
  components: {
    BaseFormField,
  },
  props: {
    fieldLabel: {
      type: String,
      default: ""
    },
    commentaire: {
      type: String,
      default: ""
    },
  },
  data () {
    return {
      openModal: false,
      date: new Date().toISOString().substr(0, 10),
    }
  },
  methods: {
    emitClick() {
      this.$emit('input', this.date);
      this.openModal = false
    },
  },
}
</script>

<style lang="scss" scoped>

.form-input-date {
  max-width: 615px !important;
}
</style>